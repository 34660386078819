<template>
  <div>
    <FullScreenDialog
      persistent
      v-model="localOpenDialog"
      :title="title"
      close-button-text=""
      :can-close="false"
    >
      <template v-slot:header-actions>
        <div class="mr-15" v-if="selectedComponent == 'scanner'">
          <v-row>
            <v-text-field
              class="mt-3"
              v-model="barcodeInputKeyboard"
              dense
              prepend-icon="mdi-code-tags"
              label="Barcode"
              hide-details="auto"
            ></v-text-field>
            <v-btn icon text class="mt-3" @click="sendBarcodeInputKeyboard">
              <v-icon>mdi-search-web</v-icon>
            </v-btn>
          </v-row>
        </div>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
      <template v-slot:content>
      <div
        v-if="!typeName"
        class="ma-10 d-flex flex-wrap justify-center align-center"
      >
        <ResponsiveCardSelector
          class="section-fade-in"
          :items="typeUpload"
          :returnObject="true"
          @click="handlePermission"
          item-key="name"
          card-max-height="400"
          card-min-height="400"
          card-max-width="400"
          card-min-width="400"
          :apply-d-flex="false"
        >
          <template v-slot:item="{ item, cardMinHeight }">
            <div
              class="pa-2 d-flex flex-column justify-center align-center"
              :style="{
                'min-height':
                  cardMinHeight.indexOf('px') != -1
                    ? cardMinHeight
                    : cardMinHeight + 'px',
                'background-image': 'linear-gradient(#8a77e2, #50bfe1)',
              }"
            >
              <div class="mt-2 font-weight-medium" style="font-size: 150%">
                {{ item.name }}
              </div>
              <v-icon class="ma-5" large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
        <StandardDialog
          v-model="showBarcode"
          title="Carico Magazzino"
          :dialog-height="null"
          dialog-max-width="500px"
          persistent
        >
          <ManualBarcodeInput
            v-model="barcodePermission"
            @confirm="uploadPermission"
          >
          </ManualBarcodeInput>
        </StandardDialog>
      </div>

      <div>
        <v-row>
          <div class="ml-5 mt-3">
            <v-btn v-if="selectedComponent !== ''" icon @click="backSelection">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div class="mt-5 ml-2 font-italic font-weight-light">
            {{ toUpperCaseFirstLetter(typeName) }}
          </div>
        </v-row>
      </div>
      <MovementUploadStepper
        v-if="selectedComponent == 'uploader'"
        :class="{
          'section-fade-in': selectedComponent !== '',
        }"
        :step-focused="stepFocused"
        @selected-file="handleSelectionFile"
        @selection-multiple-item="handleSelectionMultipleItem"
        @back-step="handleBack"
        :items-from-file="itemsFromFile"
        :items-imported="itemsImported"
        :items-in-error="itemsInError"
      >
      </MovementUploadStepper>

      <MovementScannerUpload
        v-else-if="selectedComponent == 'scanner'"
        :class="{
          'section-fade-in': selectedComponent !== '',
        }"
        @selection-items-to-upload="handleSelectionMultipleItem"
        :bus="bus"
      >
      </MovementScannerUpload>
      </template>
      <template v-slot:footer-actions>
        <div v-if="selectedComponent == 'scanner'">
          <v-btn
            color="primary"
            :disabled="!canElaborateByScanner"
            @click="uploadFromScanner"
            :loading="movementsLoading"
          >
            Elabora
            <div v-if="counter > 0">({{ counter }})</div>
          </v-btn>
        </div>
        <div
          v-if="
            stepFocused == 1 &&
            typeName !== '' &&
            selectedComponent == 'uploader'
          "
        >
          <v-btn
            class="right"
            color="primary"
            :disabled="canContinue"
            @click="continueToImport"
          >
            Continua
          </v-btn>
        </div>
        <div
          v-else-if="
            stepFocused == 2 &&
            typeName !== '' &&
            selectedComponent == 'uploader'
          "
        >
          <v-btn @click="stepFocused = stepFocused - 1" text> Indietro </v-btn>

          <v-btn
            color="primary"
            :disabled="!canElaborate || elaborating"
            :loading="movementsLoading"
            @click="elaborate"
          >
            <v-progress-circular
              v-if="elaborating"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div v-else>
              Elabora
              <div v-if="counter > 0">({{ counter }})</div>
            </div>
          </v-btn>
        </div>

        <div
          v-else-if="
            stepFocused == 3 &&
            typeName !== '' &&
            selectedComponent == 'uploader'
          "
        >
          <v-btn @click="backElaborate" text> Indietro </v-btn>
          <v-btn color="primary" @click="goToItemList"> Chiudi </v-btn>
        </div>
      </template>
    </FullScreenDialog>

    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </ErrorDialog>

    <ConfirmDialog
      v-if="openConfirmDialog"
      :value="openConfirmDialog"
      @close="openConfirmDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Scarico Prodotti"
      ><v-alert class="ma-2" dense outlined type="success">
        {{ successMessage }}
      </v-alert>
      <template> </template>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="closeAll" text color="error">Chiudi</v-btn>
        <v-btn @click="goToItemList" text>Lista Movimenti</v-btn>
      </template>
    </ConfirmDialog>
  </div>
</template>


<script>
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import FileUploader from "@/components/common/FileUploader.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import ConfirmDialog from "@/components/common/StandardDialog.vue";

import MovementUploadStepper from "./uploader/MovementUploadStepper.vue";
import MovementScannerUpload from "./scanner/MovementScannerUpload.vue";

import importerService from "@/services/importer/importer.service";
import movementsService from "@/services/warehouse/movements.service";
import currentUser from "@/services/currentUser.service";
import operatorService from '@/services/operators/operators.service.js';

import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";

import Vue from "vue";

export default {
  name: "MovementUploadDialog",
  components: {
    ResponsiveCardSelector,
    StandardDialog,
    ManualBarcodeInput,
    FileUploader,
    MovementUploadStepper,
    MovementScannerUpload,
    ErrorDialog,
    ConfirmDialog,
    FullScreenDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },
  },

  mounted() {
    this.setUser();

    this.localOpenDialog = this.openDialog;
  },

  data: function () {
    return {
      elaborating: false,
      showErrorDialog: false,
      errorMessage: "",
      canSave: false,
      successMessage: "",
      openConfirmDialog: false,
      userId: undefined,
      localOpenDialog: undefined,
      fileToImport: undefined,
      stepFocused: 1,
      itemsFromFile: [],
      itemsSelectedToUpload: [],
      itemsImported: [],
      itemsInError: [],
      counter: 0,
      selectedType: undefined,
      typeName: "",
      barcodeInputKeyboard: undefined,
      bus: new Vue(),
      typeUpload: [
        {
          name: "Scannerizza Prodotti",
          color: "#8a77e2",
          icon: "mdi-barcode-scan",
          component: "scanner",
        },
        {
          name: "Carica File",
          color: "#8a77e2",
          icon: "mdi-file-upload",
          component: "uploader",
        },
      ],
      barcodePermission: undefined,
      showBarcode: false,
      tempSelectedType: undefined,
      movementsLoading: false,
    };
  },
  computed: {
    canContinue() {
      return this.fileToImport === undefined ? true : false;
    },
    canElaborate() {
      return this.itemsSelectedToUpload.length > 0;
    },
    canElaborateByScanner() {
      return (
        this.itemsSelectedToUpload.length > 0 &&
        this.itemsSelectedToUpload.filter(
          (item) => Number(item.qtyToUpload) > 0
        ).length == this.itemsSelectedToUpload.length
      );
    },
    selectedComponent() {
      if (!!this.selectedType) {
        this.typeName = this.selectedType.name.toLowerCase();
        return this.selectedType.component;
      } else {
        return "";
      }
    },
  },
  methods: {
    uploadPermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Warehouse", "manage").then((result) => {
        if (result) {
          this.selectedType = this.tempSelectedType
        } else {
          this.selectedType = undefined
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handlePermission(selectedType) {
      this.barcodePermission = undefined
      this.showBarcode = true
      this.tempSelectedType = selectedType
    },
    closeAll() {
      this.openConfirmDialog = false;
      this.localOpenDialog = false;
    },
    setUser() {
      currentUser.getUser().then((user) => {
        this.userId = user.id;
      });
    },
    toUpperCaseFirstLetter(string) {
      return string.substring(0, 1).toUpperCase() + string.substring(1);
    },
    backSelection() {
      this.typeName = "";
      this.selectedType = undefined;
    },
    handleBack(step) {
      this.stepFocused = step;
    },
    close: function () {
      this.$emit("close");
    },
    goToItemList: function () {
      this.$router.push({
        name: "MovementItemList",
        props: {
          openDialog: true,
        },
      });
    },
    handleSelectionFile(file) {
      this.fileToImport = file;
    },
    handleSelectionMultipleItem(itemsSelected) {
      this.itemsSelectedToUpload = itemsSelected;
      this.counter = itemsSelected.length;
    },
    continueToImport() {
      let _builder = importerService._buildItemObjectFromFile;

      importerService
        ._readFile(this.fileToImport, _builder, true)
        .then((_itemsToImport) => {
          this.itemsFromFile = _itemsToImport;
          this.stepFocused = 2;
        });
    },
    elaborate() {
      this.elaborating = true;
      this.movementsLoading = true;

      importerService
        .uploadFromFile(this.fileToImport, "items", this.userId)
        .then((itemsElaborated) => {
          this.itemsImported = itemsElaborated.rowsImported;
          this.itemsInError = itemsElaborated.rowsInError;
          this.resetAfterElaborate();
          this.elaborating = false;
          this.movementsLoading = false;
        })
        .catch((itemsElaborated) => {
          this.itemsImported = itemsElaborated.rowsImported;
          this.itemsInError = itemsElaborated.rowsInError;
          this.resetAfterElaborate();
          this.elaborating = false;
          this.movementsLoading = false;
        });
    },
    uploadFromScanner() {
      this.movementsLoading = true;

      let itemsToUpload = [];
      this.itemsSelectedToUpload.map((item) => {
        const { key, ...newItem } = item;
        itemsToUpload.push(newItem);
      });
      movementsService
        .uploadFromScanner(itemsToUpload, this.userId)
        .then((movement) => {
          this.successMessage = itemVocabulary.Upload_Complete.desc;
          this.openConfirmDialog = true;
          this.resetAfterElaborate();
          this.movementsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.resetAfterElaborate();
          this.movementsLoading = false;
        });
    },
    backElaborate() {
      this.stepFocused = this.stepFocused - 1;
      this.counter = 0;
      this.itemsSelectedToUpload = [];
      this.itemsImported = [];
      this.itemsInError = [];
    },
    resetAfterElaborate() {
      this.counter = 0;
      this.itemsSelectedToUpload = [];
      this.stepFocused = 3;
    },
    sendBarcodeInputKeyboard() {
      this.bus.$emit("input-keyboard", this.barcodeInputKeyboard);
    },
  },
};
</script>

<style>
.section-fade-in {
  animation: smooth 0.8s ease-in;
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>