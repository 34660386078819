<template>
  <div>
    <v-alert dense outlined type="success">
      <div class="font-italic font-weight-light">
        Prodotti importati ({{ countItemsImported }})
      </div></v-alert
    >
    <TypeDataTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="false"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="paginatedItemsSuccess"
      :headers="headers"
      :show-actions="false"
      loading-text="Caricamento prodotti scaricati ..."
      :translator="translator"
      :loading="loading"
      :row-per-page.sync="rowPerPageSuccess"
      :page.sync="pageSuccess"
      :total-pages.sync="totalPagesSuccess"
    >
      <template v-slot:custom-qtyInStock="{ item }">
        <v-chip color="success">
          {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
        >
      </template></TypeDataTable
    >
    <v-alert color="red" dense outlined type="error">
      <div class="font-italic font-weight-light">
        Prodotti andati in errore ({{ countItemsInError }})
      </div>
    </v-alert>
    <TypeDataTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="false"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="localItemsInError"
      :headers="headersInError"
      :show-actions="false"
      :translator="translator"
      loading-text="Caricamento prodotti in errore ..."
      :loading="loading"
      :row-per-page.sync="rowPerPageError"
      :page.sync="pageError"
      :total-pages.sync="totalPagesError"
    >
      <template v-slot:custom-qtyToImport="{ item }">
        <v-chip color="error">
          {{ item["qtyToImport"] ? item["qtyToImport"] : 0 }}</v-chip
        >
      </template></TypeDataTable
    >
  </div>
</template>

<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
export default {
  name: "ElaboratedItemList",
  components: {
    TypeDataTable,
  },
  props: {
    itemsInError: {
      type: Array,
      default: function () {
        return [];
      },
    },
    itemsImported: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      totalPages: 1,
      dataTableHeightValue: 160,
      localItemsImported: [],
      localItemsInError: [],
      countItemsImported: 0,
      countItemsInError: 0,
      totalPagesSuccess: 0,
      rowPerPageSuccess: 50,
      pageSuccess: 1,
      totalPagesError: 0,
      rowPerPageError: 50,
      pageError: 1,
      paginatedItemsSuccess: [],
      headers: [
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità in Giacenza",
          value: "qtyInStock",
          type: "custom",
        },
        {
          text: "Prezzo di Vendita",
          value: "priceSelling",
        },
        {
          text: "Prezzo d'acquisto",
          value: "pricePurchase",
        },
        {
          text: "Prezzo Netto d'acquisto",
          value: "priceNetPurchase",
        },
        {
          text: "Sconto",
          value: "discount",
        },
      ],
      headersInError: [
        {
          text: "Errore",
          value: "error",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        // {
        //   text: "Quantità in Giacenza",
        //   value: "qtyInStock",
        //   type: "custom",
        // },
        {
          text: "Quantità da Importare",
          value: "qtyToImport",
          type: "custom",
        },
      ],
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.countItemsImported = this.itemsImported.length;
      this.countItemsInError = this.itemsInError.length;
      this.localItemsImported = this.itemsImported;
      this.localItemsInError = this.itemsInError;
      this.loading = false;
    }, 1000);
  },
  methods: {
    calculatePaginatedSuccess() {
      if (!this.localItemsImported || this.localItemsImported.length == 0) {
        this.paginatedItemsSuccess = []
      }

      this.totalPagesSuccess = Math.ceil(this.localItemsImported.length / this.rowPerPageSuccess)

      let offset = (this.pageSuccess - 1) * this.rowPerPageSuccess
      this.paginatedItemsSuccess = this.localItemsImported.slice(offset, (this.rowPerPageSuccess * this.pageSuccess))
    },
    calculatePaginatedError() {
      if (!this.localItemsInError || this.localItemsInError.length == 0) {
        this.paginatedItemsError = []
      }

      this.totalPagesError = Math.ceil(this.localItemsInError.length / this.rowPerPageError)

      let offset = (this.pageError - 1) * this.rowPerPageError
      this.paginatedItemsError = this.localItemsInError.slice(offset, (this.rowPerPageError * this.pageError))
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
  },
  watch: {
    localItemsImported() {
      this.calculatePaginatedSuccess()
    },
    pageSuccess() {
      this.calculatePaginatedSuccess()
    },
    rowPerPageSuccess() {
      this.pageSuccess = 1
      this.calculatePaginatedSuccess()
    },

    localItemsInError() {
      this.calculatePaginatedError()
    },
    pageError() {
      this.calculatePaginatedError()
    },
    rowPerPageError() {
      this.pageError = 1
      this.calculatePaginatedError()
    },
  }
};
</script>

<style>
</style>