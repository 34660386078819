<template>
  <div class="mt-4">
    <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
      <TypeDataTable
        v-model="selected"
        loading-text="Scannerizza prodotti"
        :headers="headers"
        :loading="loading"
        :items="itemsScanned"
        itemKey="code"
        :show-actions="false"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :height="dataTableHeight"
        :show-select="true"
        :single-select="false"
        :translator="translator"
        @input="handleSelection"
      >
        <template v-slot:custom-qtyToUpload="{ item }">
          <v-text-field
            type="number"
            @click.stop=""
            dense
            filled
            :rules="[qtyCheck]"
            hide-details="auto"
            not-specified-text="0"
            v-model="item['qtyToUpload']"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip v-if="item['qtyInStock'] > 0" color="success">
            {{ item["qtyInStock"] }}</v-chip
          >
          <v-chip v-else color="error">
            {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
          >
        </template>
      </TypeDataTable>
    </div>

    <ItemCreateDialog
      v-if="openItemCreateDialog"
      :open-dialog="openItemCreateDialog"
      :barcode-scanned="barcode"
      @close="openItemCreateDialog = false"
      :title="titleCreateItemNotPresent"
      :from-uploader="true"
      @creation-item="addItemCreatedToUploadList"
    >
    </ItemCreateDialog>

    <ItemListDialog
      v-if="openAssociatedDialog"
      @close="openAssociatedDialog = false"
      :open-dialog="openAssociatedDialog"
      :title="titleAssociatedDialog"
      :codeToAdd="barcode"
    >
    </ItemListDialog>
    
    <ErrorDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showErrorDialog"
      :title="titleError"
    >
      <v-alert class="ma-2" dense outlined type="error">
        {{ errorMessage }}
      </v-alert>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="showErrorDialog = false"
          >Chiudi</v-btn
        >
        <v-btn text color="default" @click="openBarcodeAssociatedDialog">Associa</v-btn>
        <v-btn text color="default" @click="openItemDialog">Inserisci</v-btn>
      </template>
    </ErrorDialog>
  </div>
</template>

<script>
import barcodeService from "@/services/barcode/barcode.service.js";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import ItemCreateDialog from "@/components/areas/warehouse/items/new/ItemCreateDialog.vue";
import itemService from "@/services/warehouse/items.service.js";
import ErrorDialog from "@/components/common/StandardDialog";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import Vue from 'vue';
import ItemListDialog from './ItemListDialog.vue';
export default {
  components: {
    TypeDataTable,
    ItemCreateDialog,
    ErrorDialog,
    ItemListDialog,
  },
  data() {
    return {
      filtersItem:{},
      showErrorDialog: false,
      errorMessage: "",
      titleError: "",
      selected: undefined,
      loading: false,
      dataTableHeightValue: 400,
      customers: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 1,
      resizeListener: undefined,
      itemsScanned: [],
      itemsSelectedToUpload: [],
      openItemCreateDialog: false,
      barcode: "",
      openAssociatedDialog: false,
      titleCreateItemNotPresent: "Creazione prodotto non presente a magazzino",
      titleAssociatedDialog:"",
      qtyCheck: (qty) => {
        if (qty > 0) {
          return true;
        } else {
          return itemVocabulary.Correct_Qty.desc;
        }
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function(){
        return new Vue()
      },
    },
  },
  mounted() {
    let self = this
    this.bus.$on('input-keyboard',self.handleInputKeyboard)
    this.fetchHeaders();
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
    openBarcodeAssociatedDialog(){
      this.filtersItem = {
        code: this.barcode
      }
      this.titleAssociatedDialog = "Associazione del codice " + this.barcode;
      this.openAssociatedDialog = true;
      this.showErrorDialog = false;
    },
    openItemDialog() {
      this.openItemCreateDialog = true;
      this.showErrorDialog = false;
    },
    isValid() {
      return this.itemsScanned.every(
        (item) =>
          Number(item.qtyToUpload) > 0 &&
          Number(item.qtyInStock) >= Number(item.qtyToUpload)
      );
    },
    getItem(barcode) {
      barcodeService.getByCode("items", barcode).then((results) => {
        if (!results.entity) {
          this.errorMessage = itemVocabulary.ItemNotPresent_Add.desc;
          this.showErrorDialog = true;
        } else {
          itemService
            .get(results.entity.id)
            .then((results) => {
              results.code = barcode
              results["qtyToUpload"] = 1;
              if (this.checkCodeAlreadyScanned(results)) return;
              else this.itemsScanned.push(results);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }).catch((err)=>{
        console.log(err)
      });
    },
    checkCodeAlreadyScanned(itemScanned) {
      let itemAlreadyScanned = this.itemsScanned.find(
        (item) => item.code == itemScanned.code
      );
      if (itemAlreadyScanned) {
        itemAlreadyScanned.qtyToUpload++;
        return itemAlreadyScanned;
      } else return;
    },
    fetchHeaders() {
      itemService._fieldsToUpload().then((headers) => {
        this.headers = headers;
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    addItemCreatedToUploadList(item) {
      this.openItemCreateDialog = false;
      item["qtyToUpload"] = 1;
      this.itemsScanned.push(item);
    },
    handleSelection(itemsSelected) {
      this.itemsSelectedToUpload = itemsSelected;
      this.$emit("selection-items-to-upload", itemsSelected);
    },
    handleInputKeyboard(barcode){
      this.barcode = barcode
      this.getItem(barcode)
    },
  },
  watch: {
    itemsScanned(newVal) {
      this.itemsScanned = newVal;
    },
  },
  barcode: {
    scan(barcode) {
      this.barcode = barcode;
      this.getItem(barcode);
    },
  },
};
</script>

<style>
</style>