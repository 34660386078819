var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FullScreenDialog',{attrs:{"persistent":"","title":_vm.title,"close-button-text":"","can-close":false},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [(_vm.selectedComponent == 'scanner')?_c('div',{staticClass:"mr-15"},[_c('v-row',[_c('v-text-field',{staticClass:"mt-3",attrs:{"dense":"","prepend-icon":"mdi-code-tags","label":"Barcode","hide-details":"auto"},model:{value:(_vm.barcodeInputKeyboard),callback:function ($$v) {_vm.barcodeInputKeyboard=$$v},expression:"barcodeInputKeyboard"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","text":""},on:{"click":_vm.sendBarcodeInputKeyboard}},[_c('v-icon',[_vm._v("mdi-search-web")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true},{key:"content",fn:function(){return [(!_vm.typeName)?_c('div',{staticClass:"ma-10 d-flex flex-wrap justify-center align-center"},[_c('ResponsiveCardSelector',{staticClass:"section-fade-in",attrs:{"items":_vm.typeUpload,"returnObject":true,"item-key":"name","card-max-height":"400","card-min-height":"400","card-max-width":"400","card-min-width":"400","apply-d-flex":false},on:{"click":_vm.handlePermission},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({
              'min-height':
                cardMinHeight.indexOf('px') != -1
                  ? cardMinHeight
                  : cardMinHeight + 'px',
              'background-image': 'linear-gradient(#8a77e2, #50bfe1)',
            })},[_c('div',{staticClass:"mt-2 font-weight-medium",staticStyle:{"font-size":"150%"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{staticClass:"ma-5",attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,false,486333400)}),_c('StandardDialog',{attrs:{"title":"Carico Magazzino","dialog-height":null,"dialog-max-width":"500px","persistent":""},model:{value:(_vm.showBarcode),callback:function ($$v) {_vm.showBarcode=$$v},expression:"showBarcode"}},[_c('ManualBarcodeInput',{on:{"confirm":_vm.uploadPermission},model:{value:(_vm.barcodePermission),callback:function ($$v) {_vm.barcodePermission=$$v},expression:"barcodePermission"}})],1)],1):_vm._e(),_c('div',[_c('v-row',[_c('div',{staticClass:"ml-5 mt-3"},[(_vm.selectedComponent !== '')?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.backSelection}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e()],1),_c('div',{staticClass:"mt-5 ml-2 font-italic font-weight-light"},[_vm._v(" "+_vm._s(_vm.toUpperCaseFirstLetter(_vm.typeName))+" ")])])],1),(_vm.selectedComponent == 'uploader')?_c('MovementUploadStepper',{class:{
        'section-fade-in': _vm.selectedComponent !== '',
      },attrs:{"step-focused":_vm.stepFocused,"items-from-file":_vm.itemsFromFile,"items-imported":_vm.itemsImported,"items-in-error":_vm.itemsInError},on:{"selected-file":_vm.handleSelectionFile,"selection-multiple-item":_vm.handleSelectionMultipleItem,"back-step":_vm.handleBack}}):(_vm.selectedComponent == 'scanner')?_c('MovementScannerUpload',{class:{
        'section-fade-in': _vm.selectedComponent !== '',
      },attrs:{"bus":_vm.bus},on:{"selection-items-to-upload":_vm.handleSelectionMultipleItem}}):_vm._e()]},proxy:true},{key:"footer-actions",fn:function(){return [(_vm.selectedComponent == 'scanner')?_c('div',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canElaborateByScanner,"loading":_vm.movementsLoading},on:{"click":_vm.uploadFromScanner}},[_vm._v(" Elabora "),(_vm.counter > 0)?_c('div',[_vm._v("("+_vm._s(_vm.counter)+")")]):_vm._e()])],1):_vm._e(),(
          _vm.stepFocused == 1 &&
          _vm.typeName !== '' &&
          _vm.selectedComponent == 'uploader'
        )?_c('div',[_c('v-btn',{staticClass:"right",attrs:{"color":"primary","disabled":_vm.canContinue},on:{"click":_vm.continueToImport}},[_vm._v(" Continua ")])],1):(
          _vm.stepFocused == 2 &&
          _vm.typeName !== '' &&
          _vm.selectedComponent == 'uploader'
        )?_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepFocused = _vm.stepFocused - 1}}},[_vm._v(" Indietro ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canElaborate || _vm.elaborating,"loading":_vm.movementsLoading},on:{"click":_vm.elaborate}},[(_vm.elaborating)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',[_vm._v(" Elabora "),(_vm.counter > 0)?_c('div',[_vm._v("("+_vm._s(_vm.counter)+")")]):_vm._e()])],1)],1):(
          _vm.stepFocused == 3 &&
          _vm.typeName !== '' &&
          _vm.selectedComponent == 'uploader'
        )?_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.backElaborate}},[_vm._v(" Indietro ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToItemList}},[_vm._v(" Chiudi ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.localOpenDialog),callback:function ($$v) {_vm.localOpenDialog=$$v},expression:"localOpenDialog"}}),_c('ErrorDialog',{attrs:{"dialog-width":"360","dialog-height":"360","persistent":"","title":"Errore"},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),(_vm.openConfirmDialog)?_c('ConfirmDialog',{attrs:{"value":_vm.openConfirmDialog,"dialog-width":"360","dialog-height":"calc(200px-10px)","persistent":"","title":"Scarico Prodotti"},on:{"close":function($event){_vm.openConfirmDialog = false}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.closeAll}},[_vm._v("Chiudi")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.goToItemList}},[_vm._v("Lista Movimenti")])]},proxy:true}],null,false,959190143)},[_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),void 0],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }