<template>
  <div>
    <StandardDialog
      v-if="openDialog"
      v-model="openDialog"
      persistent
      :title="title"
      dialog-height="calc(650-1)"
      dialog-width="90vw"
    >
      <template v-slot:header-actions>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
      <div v-if="!showItemDetail" class="d-flex pa-5" style="width: 100%">
        <AdvancedFilter
          style="width:99%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Descrizione: {{ filter.value }}
          </template>

          <template v-slot:custom-supplierId="{filter}">
            <v-row class="pa-6">
              <v-col>
                <SuppliersAutocomplete
                  v-model="filter.value"
                  dense
                ></SuppliersAutocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-departmentId="{filter}">
            <v-row class="pa-6">
              <v-col>
                <DepartmentsAutocomplete
                  v-model="filter.value"
                  dense
                  multiple
                ></DepartmentsAutocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-tag="{filter}">
            <v-row class="pa-6">
              <v-col>
                <TagsAutocomplete
                  v-model="filter.value"
                  dense
                  :filled="false"
                  multiple
                ></TagsAutocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-unitOfMeasureId="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-autocomplete
                  v-model="filter.value"
                  itemText="name"
                  itemValue="id"
                  :chips="true"
                  :deletableChips="true"
                  :smallChips="true"
                  label="Unità Di Misura"
                  :items="unitOfMeasureItems"
                  dense
                  hide-details="auto"
                ></v-autocomplete> 
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-packageTypeId="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-autocomplete
                  v-model="filter.value"
                  itemText="name"
                  itemValue="id"
                  :chips="true"
                  :deletableChips="true"
                  :smallChips="true"
                  label="Confezione"
                  :items="packageTypeItems"
                  dense
                  filled
                  hide-details="auto"
                ></v-autocomplete> 
              </v-col>
            </v-row>
          </template>

        </AdvancedFilter>  
      </div>

      <div
        v-if="!showItemDetail"
        style="height: 100%; overflow-y: hidden"
        ref="dataTableWrapper"
      >
        <TypeDataTable
          :select-on-row-click="true"
          :single-select="true"
          :height="dataTableHeight"
          :show-select="false"
          item-key="id"
          fixed-header
          class="mt-10"
          :items="itemsToAssociateBarcode"
          :headers="headersItem"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :show-actions="false"
          :translator="translator"
          @input="handleSelection"
        >
          <template v-slot:custom-qtyInStock="{ item }">
            <v-chip v-if="item['qtyInStock'] > 0" color="success">
              {{ item["qtyInStock"] }}</v-chip
            >
            <v-chip v-else color="error">
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >
          </template>
          <template v-slot:custom-qtyTidying="{ item }">
            <v-text-field
              type="number"
              @click.stop=""
              dense
              filled
              :rules="[qtyCheck]"
              hide-details="auto"
              not-specified-text="0"
              v-model="item['qtyTidying']"
            >
            </v-text-field>
          </template>
        </TypeDataTable>
      </div>

      <div v-else>
        <ItemForm
          :barcode-scanned="codeToAdd"
          :item-id="selectedItemId"
          class="py-2"
          :loading="loadingForm"
          :editing="true"
          :associateBarcode="true"
        ></ItemForm>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          v-if="showItemDetail"
          @click="showItemDetail = false"
          >Indietro</v-btn
        >
        <v-btn text @click="save" v-if="showItemDetail">Salva</v-btn>
        <v-btn text @click="close" v-if="!showItemDetail">Chiudi</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import itemService from "@/services/warehouse/items.service.js";
import ItemForm from "@/components/areas/warehouse/items/ItemForm.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import _itemListFilter from "@/components/areas/warehouse/items/filters/itemList";

export default {
  components: { 
    AdvancedFilter, 
    StandardDialog, 
    TypeDataTable, 
    ItemForm 
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: () => {},
    },
    codeToAdd: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showItemDetail: false,
      selectedItemId: 0,
      loadingForm: false,
      localDialog: true,
      headersItem: [],
      itemsToAssociateBarcode: [],
      dataTableHeightValue: 400,
      page: 1,
      rowPerPage: 100,
      totalPages: 1,
      loading: false,
      itemListFilter: [],
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'lastPurchaseDate', name: 'Data Ultimo Acquisto', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'equal', field: 'supplierId', name: 'Fornitore', label: 'Fornitore', color: "", value: [], icon: "mdi-dolly" },
        { type: 'string', operator: 'equal', field: 'items.code', name: 'Barcode', label: 'Barcode', color:"", value: undefined, icon: "mdi-barcode" },
        { type: 'cash', operator: 'equal', field: 'priceSelling', name: 'Prezzo di vendita', label: 'Prezzo di vendita', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'priceNetPurchase', name: "Prezzo d'acquisto", label: "Prezzo d'acquisto", color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'priceNetPurchase', name: "Prezzo Netto d'acquisto", label: "Prezzo Netto d'acquisto", color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'tag', name: 'Tag Famiglia', label: 'Tag', color: "", value: undefined, icon: 'mdi-tag' },
        { type: 'custom', operator: 'custom', field: 'tagCategory', name: 'Categoria Tag', label: 'Categoria Tag', color: "", value: undefined, icon: 'mdi-tag-multiple' },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'departmentId', name: 'Reparto', label: 'Reparto', color: "", value: [], icon: "mdi-home-city" },
        { type: 'number', operator: 'equal', field: 'discount', name: 'Sconto', label: 'Sconto', color: "", value: undefined, icon: "mdi-sale" },
        { type: 'boolean', operator: 'equal', field: 'isInternal', name: 'Uso Interno', label: 'Uso Interno', color: "", value: false },
        { type: 'custom', operator: 'equal', field: 'packageTypeId', name: 'Confezione', label: 'Confezione', color: "", value: undefined, icon: "mdi-package-variant" },
        { type: 'custom', operator: 'equal', field: 'unitOfMeasureId', name: 'Unità di misura', label: 'Unità di misura', color: "", value: undefined, icon: "mdi-ruler-square-compass" },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Prodotto', color: "", value: "" },
      filtersValue: [
      ],
    };
  },
  mounted() {
    this.itemListFilter = [];
    this.itemListFilter = _itemListFilter;

    this.fetchItems(this.filters);
    this.fetchHeaders();
    if (!!this.$refs.dataTableWrapper) {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
      this.resizeListener = function () {
        this.dataTableHeightValue =
          this.$refs.dataTableWrapper.offsetHeight - 60;
      };
      this.resizeListener = this.resizeListener.bind(this);
      window.addEventListener("resize", this.resizeListener);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
    save() {
      let currentItemEdit = this.$store.state.warehouse.items.data.item;
      let barcodeEdit = this.$store.state.warehouse.items.data.item.barcode;
      currentItemEdit.id = this.selectedItemId;
      itemService
        .update({ item: currentItemEdit, barcode: barcodeEdit })
        .then((value) => {
          this.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    fetchItems() {
      this.loading = true;
      this.itemsToAssociateBarcode = [];
      let filters = [...this.filtersValue]

      itemService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.itemsToAssociateBarcode = results.rows;
        this.loading = false;
      });
    },
    fetchHeaders() {
      itemService._fields().then((headers) => {
        this.headersItem = headers;
      });
    },
    handleSelection(row) {
      this.selectedItemId = Number(row[0].id);
      this.showItemDetail = true;
    },
    close() {
      this.selectedItemId = 0;
      this.showItemDetail = false;
      this.$emit("close");
    },
    applyFilters(filters) {
      this.fetchItems();
    },
  },
  watch: {
    title(newVal) {
      this.title = newVal;
    },
    selectedItemId(newVal) {
      this.selectedItemId = newVal;
    },
    filters(newVal) {
      this.filters = newVal;
    },
    page() {
      this.fetchItems();
    },
    rowPerPage() {
      this.fetchItems();
    },
    itemListFilter(newVal) {
      this.itemListFilter = newVal;
    },
  },
};
</script>

<style>
</style>